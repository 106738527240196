import { defineStore } from 'pinia';
import { API } from '@/api/plugin';
import useSystemStore, { MessageType } from '@/store/system';
import useTenantStore from '@/store/tenant';
import useConfigurationStore from '@/store/configuration';
import { type Taxonomy } from '@/api/schema';

interface TaxonomyState {
  taxonomies: Taxonomy['TaxonomyDto'][];
  inUseTaxonomy?: Taxonomy['TaxonomyDto'];
  inUseTaxonomyTree?: Taxonomy['TaxonomyItemDto'][];
  selectedTaxonomy?: Taxonomy['TaxonomyDto'];
  selectedTaxonomyTree?: Taxonomy['TaxonomyItemDto'][];
}

export default defineStore('taxonomy', {
  state: (): TaxonomyState => ({
    taxonomies: [],
    inUseTaxonomy: undefined,
    inUseTaxonomyTree: undefined,
    selectedTaxonomy: undefined,
    selectedTaxonomyTree: undefined,
  }),

  getters: {
    getTaxonomies: (state) => state.taxonomies,
    getSelectedTaxonomy: (state) => state.selectedTaxonomy,
    getSelectedTaxonomyName: (state) => {
      if (state.selectedTaxonomy && state.selectedTaxonomy.taxonomyName) {
        return state.selectedTaxonomy.taxonomyName
      }
      return '';
    },
    getSelectedTaxonomyVersion: (state) => {
      if (state.selectedTaxonomy && state.selectedTaxonomy.taxonomyVersion) {
        return state.selectedTaxonomy.taxonomyVersion
      }
      return '';
    },
    getSelectedTaxonomyTree: (state) => state.selectedTaxonomyTree,
    getInUseTaxonomy: (state) => state.inUseTaxonomy,
    getInUseTaxonomyTree: (state) => state.inUseTaxonomyTree,
    getInUseTaxonomyTreeFlattened: (state): Taxonomy['TaxonomyItemDto'][] => {
      const inUseTaxonomyTreeFlattened: Taxonomy['TaxonomyItemDto'][] = []
      const iterateTaxonomyTreeWithChildren = (taxonomyObjectArray: Taxonomy['TaxonomyItemDto'][] = []) => {
        taxonomyObjectArray?.map((taxonomyObject: any) => {
          inUseTaxonomyTreeFlattened.push(taxonomyObject)
          Object.keys(taxonomyObject).forEach(key => {
            if (typeof taxonomyObject[key] === 'object' && taxonomyObject[key] !== null && key === 'children') {
              iterateTaxonomyTreeWithChildren(taxonomyObject['children'])
            }
          })
        })
      }
      iterateTaxonomyTreeWithChildren(state.inUseTaxonomyTree);
      return inUseTaxonomyTreeFlattened
    },
    getInUseTopTaxonomiesFlattened: (state): Taxonomy['TaxonomyItemDto'][] => {
      const inUseTopTaxonomiesFlattened: Taxonomy['TaxonomyItemDto'][] = []
      state.inUseTaxonomyTree?.map((taxonomyLevelOne) => {
        inUseTopTaxonomiesFlattened.push(taxonomyLevelOne)
      })
      return inUseTopTaxonomiesFlattened
    },
    getInUseTaxonomyTreeFlattenedNoChildren: (state): Taxonomy['TaxonomyItemDto'][] => {
      const inUseTaxonomyTreeFlattened: Taxonomy['TaxonomyItemDto'][] = []
      const iterateTaxonomyTreeNoChildren = (taxonomyObjectArray: Taxonomy['TaxonomyItemDto'][] = []) => {
        taxonomyObjectArray?.map((taxonomyObject: any) => {
          inUseTaxonomyTreeFlattened.push({ item: taxonomyObject['item'], level: taxonomyObject['level'] })
          Object.keys(taxonomyObject).forEach(key => {
            if (typeof taxonomyObject[key] === 'object' && taxonomyObject[key] !== null && key === 'children') {
              iterateTaxonomyTreeNoChildren(taxonomyObject['children'])
            }
          })
        })
      }
      iterateTaxonomyTreeNoChildren(state.inUseTaxonomyTree);
      return inUseTaxonomyTreeFlattened
    },
  },

  actions: {
    setTaxonomies(payload: ({ [x: string]: unknown; taxonomyId?: string | undefined; uploadDate?: string | undefined; tenantId: string; taxonomyName: string; taxonomyVersion: string; } | undefined)[]) {
      this.taxonomies = payload;
    },

    setSelectedTaxonomy(payload?: { [x: string]: unknown; taxonomyId?: string | undefined; uploadDate?: string | undefined; tenantId: string; taxonomyName: string; taxonomyVersion: string; } | undefined) {
      this.selectedTaxonomy = { ...payload };
    },

    setSelectedTaxonomyTree(payload?: []) {
      this.selectedTaxonomyTree = payload;
    },

    setInUseTaxonomy(payload: { [x: string]: unknown; taxonomyId?: string | undefined; uploadDate?: string | undefined; tenantId: string; taxonomyName: string; taxonomyVersion: string; } | undefined) {
      this.inUseTaxonomy = payload;
    },

    setInUseTaxonomyTree(payload: ({ [x: string]: unknown; taxonomyPath?: { [x: string]: unknown; }[] | undefined; children?: { [x: string]: unknown; }[] | undefined; item: string; level: string; } | undefined)[] | undefined) {
      this.inUseTaxonomyTree = payload;
    },

    sortTaxonomiesByDate(data: Taxonomy['TaxonomyDto'][]) {
      return data.sort((a: any, b: any) => {
        const bDate: any = new Date(b.uploadDate);
        const aDate: any = new Date(a.uploadDate);
        return bDate - aDate;
      });
    },

    async fetchTaxonomies() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Taxonomy.getTaxonomies(
        tenantStore.getSelectedTenantId,
      ).then((response) => {
        const sortedTaxonomies = this.sortTaxonomiesByDate(response.data);
        this.setTaxonomies([...sortedTaxonomies]);
      }).catch((error) => {
        console.error(error)
        // systemStore.addMessage({ title: 'Error fetching taxonomies', message: error as string, type: MessageType.Error });
      });
    },

    async fetchInUseTaxonomy() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      const configurationStore = useConfigurationStore();
      await configurationStore.fetchLegacyConfiguration()
        .then(() => {
          if (configurationStore.legacyConfiguration.attributes?.taxonomy.name !== 'initial') {
            this.setInUseTaxonomy({
              tenantId: tenantStore.getSelectedTenantId,
              taxonomyName: configurationStore.legacyConfiguration.attributes?.taxonomy.name,
              taxonomyVersion: configurationStore.legacyConfiguration.attributes?.taxonomy.version
            });
          }
        }).catch((error) => {
          console.error(error)
          // systemStore.addMessage({ title: 'Error fetching taxonomy', message: error as string, type: MessageType.Error });
        });
    },

    async fetchSelectedTaxonomyTree() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Taxonomy.getTaxonomyTree(
        tenantStore.getSelectedTenantId,
        this.getSelectedTaxonomyName,
        this.getSelectedTaxonomyVersion,
      ).then((response) => {
        this.setSelectedTaxonomyTree(response.data);
      }).catch((error) => {
        console.error(error)
        // systemStore.addMessage({ title: 'Error fetching taxonomy tree', message: error as string, type: MessageType.Error });
      });
    },

    async fetchInUseTaxonomyTree() {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      const configurationStore = useConfigurationStore();
      await configurationStore.fetchLegacyConfiguration();
      await API.Taxonomy.getTaxonomyTree(
        tenantStore.getSelectedTenantId,
        configurationStore.legacyConfiguration.attributes?.taxonomy.name,
        configurationStore.legacyConfiguration.attributes?.taxonomy.version,
      ).then((response) => {
        this.setInUseTaxonomyTree(response.data);
      }).catch((error) => {
        console.error(error)
        // systemStore.addMessage({ title: 'Error fetching taxonomy tree', message: error as string, type: MessageType.Error });
      });
    },

    async uploadTaxonomy(formData: FormData, taxonomyName: string) {
      const systemStore = useSystemStore();
      const tenantStore = useTenantStore();
      await API.Taxonomy.uploadAsset(
        formData,
        tenantStore.getSelectedTenantId,
        taxonomyName,
      ).then(async (response) => {
        await this.fetchTaxonomies();
        const { uploadStatus, tenantId } = response.data;
        const message = uploadStatus.includes('Duplicate taxonomy') ?  `The taxonomy "${taxonomyName}" for tenant "${tenantId}" was not uploaded because it is a duplicate.` : 'Taxonomy uploaded successfully';
        const title = uploadStatus.includes('Duplicate taxonomy') ? 'Duplicate Taxonomy' : 'Success';
        systemStore.addMessage({ title: title, message: message, type: uploadStatus.includes('Duplicate taxonomy') ? MessageType.Error: MessageType.Success });
      }).catch((error) => {
        systemStore.addMessage({ title: 'Error uploading taxonomy', message: error as string, type: MessageType.Error });
      });
    },
  },
});
